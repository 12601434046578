<template>
  <div class="column no-padding is-full">
    <div class="card">
      <header class="card-header has-background-primary">
        <div @click.stop="$router.go(-1)" class="card-header-icon">
          <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
        </div>
        <p class="card-header-title has-text-light">AKTIVITAS HARIAN DETAIL</p>
        <template v-if="canChange">
          <router-link
            :to="{ name: 'kegiatan-pspd-change', params: { id: paramsId } }"
            class="card-header-icon has-text-light"
          >
            <b-icon class="has-text-light" size="is-small" icon="edit" />
          </router-link>
          <a class="card-header-icon" @click.stop.prevent="confirmDelete">
            <b-icon icon="trash" size="is-small" class="has-text-light" />
          </a>
        </template>
      </header>
      <div class="card-content events-card">
      <div class="columns is-mobile">
        <div class="column detail is-12">
          <span class="is-pulled-right">
            <small>{{ kegiatan.tanggal }}</small>
          </span>
        </div>
      </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Stase</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.stase.nama }}</div>
        </div>
        <div v-if="kegiatan.koordinator" class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Koordinator</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.koordinator }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Preceptor</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.preceptor.nama }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Mahasiswa</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.pspd }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Lokasi</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="kegiatan.lokasi">{{ kegiatan.lokasi.nama }}</template>
            <template v-else>-</template>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Metode</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.metode.nama }} [{{ kegiatan.jenis }}]</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Aktivitas</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.kegiatan }}</div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Catatan</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.catatan }}</div>
        </div>
        <!-- <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Nilai Sikap</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="kegiatan.extra_data.can_verifikasi">
              <b-field :type="{'is-danger': errorMap.nilai_sikap}" :message="errorMap.nilai_sikap">
                <b-numberinput
                  min="0"
                  max="100"
                  step="0.01"
                  v-model="kegiatan.nilai_sikap"
                  :controls="false"
                  @input="validateInput('nilai_sikap')"
                ></b-numberinput>
              </b-field>
            </template>
            <template v-else>
              <span v-if="kegiatan.nilai_sikap !== null">{{ kegiatan.nilai_sikap }}</span>
              <span v-else class="icon has-text-info">
                <b-icon size="is-small" icon="minus" />
              </span>
            </template>
          </div>
        </div>
        <div class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold">Verifikasi</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">
            <template v-if="kegiatan.extra_data.can_verifikasi">
              <b-field :type="{'is-danger': errorMap.verifikasi}" :message="errorMap.verifikasi">
                <b-select
                  @input="validateInput('verifikasi')"
                  v-model="kegiatan.verifikasi"
                  placeholder="fieldTitle"
                >
                  <option value="Belum">Ver: -</option>
                  <option value="Ya">Ver: ✓</option>
                  <option value="Tidak">Ver: ✗</option>
                </b-select>
              </b-field>
            </template>
            <template v-else><hasil-mark :hasil="kegiatan.verifikasi"></hasil-mark></template>
          </div>
        </div>
        <div v-if="kegiatan.waktu_verifikasi" class="columns is-mobile">
          <div class="column detail has-text-weight-semibold is-4">Waktu Verifikasi</div>
          <div class="column detail has-text-weight-semibold is-1">:</div>
          <div class="column detail has-text-left">{{ kegiatan.waktu_verifikasi }}</div>
        </div>
        <div v-if="kegiatan.extra_data.can_verifikasi" class="columns is-mobile">
          <div class="column detail is-4 has-text-weight-semibold"></div>
          <div class="column detail has-text-weight-semibold is-1"></div>
          <div class="column detail has-text-left">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop.prevent="saveObject"
              :disabled="!isValid"
            >Simpan</b-button>
          </div>
        </div> -->

        <div v-if="canChange" class="columns is-mobile">
          <div class="column">
            <b-button loading v-if="isUploading">Loading</b-button>
            <b-field v-else class="file">
              <b-upload v-model="kegiatan.foto" @input="resize" accept=".jpg, .jpeg, .png">
                <a class="button is-primary">
                  <b-icon icon="upload"></b-icon>
                  <span>Upload Foto</span>
                </a>
              </b-upload>
              <span class="file-name" v-if="kegiatan.foto">{{ kegiatan.foto.name }}</span>
            </b-field>
          </div>
        </div>

        <ul class="box-gallery-container three-cols">
          <li
            v-for="foto in kegiatan.foto_set"
            :key="foto.id"
            class="box-gallery show"
            style="transition-delay: 0s;"
          >
            <div class="inner">
              <a v-if="canChange" @click="confirmDeleteFoto(foto)">
                <b-icon icon="times-circle" class="is-pulled-right"></b-icon>
              </a>
              <a :href="getWebpPath(foto.url)" class="glightbox">
                <img class="lampiran" :src="getWebpPath(foto.url)" />
              </a>
            </div>
          </li>
        </ul>
        <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
      </div>
    </div>
  </div>
</template>


<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
import GLightbox from "glightbox";
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import deleteMixin from "@/apps/core/mixins/deleteMixin";
import KegiatanPSPD from "../models/kegiatanDetail.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";
import { webpPath } from "@/apps/core/modules/utils.js";

export default {
  name: "KegiatanPSPDDetail",
  mixins: [createUpdateMixin, deleteMixin],
  props: ["title"],
  // components: {
  //   HasilMark: () => import("@/apps/core/components/HasilMark.vue")
  // },
  data() {
    this.kegiatanMdl = new KegiatanPSPD();
    this.objectMdl = this.kegiatanMdl;
    let obv = this.kegiatanMdl.getObservables();
    obv.paramsId = this.$route.params.id;
    this.uploadPhoto = new UploadPhoto("kegiatanpspd", this.notify);
    obv.isUploading = false;
    return obv;
  },
  computed: {
    ...mapState("accounts", ["userId"]),
    ...mapGetters("accounts", ["isPreceptor", "isPSPD"]),
    canChange() {
      return this.isLoaded && this.kegiatan.extra_data.can_change;
    }
  },
  methods: {
    getWebpPath(url) {
      return webpPath(url);
    },
    notify(uploading) {
      this.isUploading = uploading;
    },
    onLoaded() {
      if (!this.canChange) return;
      this.uploadPhoto.requestAWS();
    },
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.uploadPhoto.upload(foto, () => this.saveFoto(url), null, () => {
          this.onLoaded();
        });
      });
    },
    // onSaved(respData) {
    //   this.kegiatan.waktu_verifikasi = respData.waktu_verifikasi;
    // },
    saveFoto(url) {
      let uploadDocUrl = `${APP_CONFIG.baseAPIURL}/aktivitas/kegiatan-pspd/foto/`;
      let data = { kegiatan: this.kegiatan.id, url: url };
      axios
        .post(uploadDocUrl, data)
        .then(response => {
          this.kegiatan.foto_set.splice(0, 0, {
            id: response.data.id,
            url: response.data.url
          });
          this.kegiatan.foto = null;
          this.$buefy.toast.open("Foto berhasil di upload.");
        })
        .catch(() => {
          this.$buefy.toast.open("Foto gagal di upload.");
        });
    },
    confirmDeleteFoto(foto) {
      this.$buefy.dialog.confirm({
        title: "Menghapus Data",
        message: "Anda yakin akan <b>menghapus</b> foto ini?",
        confirmText: "Hapus Foto",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          let fotoUrl = `foto/${foto.id}`;
          this.kegiatanMdl.delete(fotoUrl, () => {
            const idx = this.kegiatan.foto_set.indexOf(foto);
            this.kegiatan.foto_set.splice(idx, 1);
          });
        }
      });
    }
  },
  watch: {
    kegiatan: {
      // kegiatan berubah, maka tombol simpan bisa aktif
      handler(newValue, oldValue) {
        if (!this.kegiatanMdl.getEdited()) {
          this.kegiatanMdl.setEdited(!!oldValue.id);
        }
      },
      deep: true
    }
  },
  mounted() {
    this.glightbox = GLightbox();
  },
  updated() {
    this.glightbox.reload();
  }
};
</script>

<style scoped lang="scss">
@import "@/apps/core/assets/scss/detailview.scss";
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../core/assets/css/glightbox.css";
</style>
